<template>
  <el-dialog custom-class="minWidthLandAdd" title="用户服务协议" :visible.sync="visible" :close-on-click-modal="false" @close="onClose" draggable>
    <div style="text-align: left">
      <div style="line-height: 30px;">
        <p>【引言】</p>
        <p>欢迎您使用德阳市土地二级市场交易平台!</p>
        <p>
          &nbsp;&nbsp;&nbsp; 德阳市土地二级市场交易平台是由德阳市自然资源和规划局主办,德阳市自然资源和规划局负责管理的服务平台(以下简称“本平台”),本平台根据《用户服务协议》(以下简称“本协议”)为您提供服务。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp; 为使用本平台服务,请您务必审慎阅读、充分理解本协议各条款内容,特别是免除或者限制责任的条款、对用户权利进行限制的条款等。限制、免责条款或者其他涉及您重大权益的条款可能以加粗及加横线形式提示您重点注意。
        </p>
        <p> &nbsp;&nbsp;&nbsp; 在使用之前,您应当审慎阅读、充分理解本协议的全部内容。您点击“已阅读并同意协议(具体措辞详见注册页面)”及进行注册、使用、获取用户账号、登录及使用本平台相关服务的行为或者以其他任何明示方式表示接受本协议的，均视为您已认真阅读并同意签署本协议。</p>
        <p> &nbsp;&nbsp;&nbsp; 用户声明,在接受本协议并注册成为本平台用户时，是具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人，或者是在中华人民共和国合法开展经营活动或其他业务的法人或其他组织。如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人(以下简称"监护人")的陪同下阅读和签署本协议。</p>
        <p> &nbsp;&nbsp;&nbsp; 您有违反本协议的任何行为时,本平台有权根据您违反约定的情形单方采取限制、中止或终止向您提供服务等措施，并有权追究您相关责任。</p>
        <p>一、【定义与解释】</p>
        <p>1.1 用户是指注册登录、使用本平台服务的个人或组织，在本协议中更多地称为“您”。</p>
        <p>1.2 本平台是向用户提供用户注册登录、实名核验以及与此相关的身份认证技术服务的平台。</p>
        <p>二、【协议适用范围】</p>
        <p>2.1 本协议是本平台制定的关于获取和使用本平台账号，并基于该账号登陆第三方应用(包括第三方网站、系统等，下同)的相关规则。本规则适用于需要注册或使用本平台账号的全部软件或服务，含第三方应用。(本协议中的“第三方”若无特别说明，均为为用户提供相应网站、应用或其他服务的政府部门、公用企事业单位或其他公共服务组织。)</p>
        <p>2.2 本协议项下的登录服务包括两种情况：本平台向用户提供的通过本平台注册账号登录使用第三方应用的服务;本平台向用户提供的通过第三方账号登录使用第三方应用的服务(以下简称“本服务”)。</p>
        <p>2.3 您通过本平台提供的账号使用相关的软件和服务时，须同时遵守各项服务包括第三方应用的单独用户协议及隐私条款。</p>
        <p>三、【账号注册及管理】</p>
        <p>3.1 账号注册</p>
        <p>3.1.1 本平台个人用户可通过手机号码、手机验证码进行注册登录，企业用户可选择CFCA进行登录。</p>
        <p>3.1.2 您在注册及使用本平台服务时，必须向本平台提供真实、准确、完整、合法有效的资料，且有义务维持并及时更新您的资料。其中，根据实名认证方式的不同，个人所需提供的实名认证资料包括但不限于身份证信息、手机号码、手机短信、人脸生物信息等。法人需要提供的实名认证资料包括但不限于统一信用代码信息、单位法定代表人姓名及其身份证信息。若本平台有合理理由怀疑您提供的身份信息及相关资料错误、不实、过时或不完整，本平台有权限制或停止向您提供部分或全部服务，您同时须承担因此引起的相应责任及后果。</p>
        <p>3.1.3 您不得冒用他人名义申请非本人的用户账号。如有违反，经核实后，本平台或第三方服务平台有权删除其账号。对于冒用他人名义申请非本人的用户账号而引起的一切责任，由冒用者承担。</p>
        <p>3.1.4 您确认并授权，本平台有权核对您的有效身份信息，并留存有效必要文件，您应积极配合，否则本平台有权限制或停止向您提供部分或全部服务。</p>
        <p>3.1.5 若您为法人用户，您的工商登记信息发生变更的，您应及时更改账号中的信息。法人合并、解散、注销、宣告破产或倒闭，或被吊销营业执照等主体资格终止的，您应于上述情况发生的时候尽快告知本平台，终止使用您以该法人用户注册的本平台账号，否则，本平台有权随时注销您的账号而不承担任何违约责任。因您未尽该通知义务给本平台造成损失的，由您负责全部赔偿。</p>
        <p>3.1.6 您同意并确认通过验证方式登录本平台后，其在本平台以及其他第三方平台申请办理的各项业务，均视为您本人行为。</p>
        <p>3.2 账号管理</p>
        <p>3.2.1 本平台账号的所有权归本平台所有，用户完成申请注册手续后，仅获得账号的使用权，且该使用权仅属于初始申请注册人。用户不得赠与、转让、出借或者以其他方式许可他人使用该账号。否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</p>
        <p>3.2.2 您有责任妥善保管注册账号信息的安全，且确认只有您本人可以使用您的账号，您需要对您注册账号下的行为承担法律责任。在您决定不再使用该账号时，您应向本平台申请注销该账号。您应对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。</p>
        <p>3.2.3 如果您为单位法定代表人，您以个人身份登录且通过实名身份核验后可选择以法定代表人身份办理名下单位的业务事项。您应确认您为该单位法定代表人后方可使用该项功能，如果您非该单位法定代表人，您不得以该单位法定代表人身份登录本平台办理业务，并应及时向本平台反馈相关信息。如因为您假冒法定代表人身份导致第三人利益受损的，由您承担全部责任。</p>
        <p>3.2.4 如果您当前使用的账号并不是您初始申请注册的或者不是通过本平台提供的其他途径获得的，您不得用该账号登录或进行任何操作，并请您在第一时间通知本平台。</p>
        <p>3.2.5 如您违反相关法律法规、本规则或其他相关协议、规则，本平台有权限制、中止、冻结或终止您对账号的使用，且根据实际情况决定是否恢复您对账号的使用，而由此产生的损失由您自行承担。</p>
        <p>四、【用户个人信息保护】</p>
        <p>4.1 本平台非常重视对用户个人信息的保护。本平台将按照本协议及《隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《隐私政策》的内容为准。</p>
        <p>4.2 如您认为您的个人信息被本平台超目的使用、或被其他用户侵权，请立即与本平台联系，并按照要求提供相应的证明资料，本平台将及时调查核实处理。</p>
        <p>4.3 对于本平台会如何收集、使用、存储和保护您的个人信息及您享有何种权利，您还可以阅读《隐私政策》予以进一步了解。</p>
        <p>五、【用户行为规范】</p>
        <p>5.1 您不得因非正常使用服务之目的恶意注册、获取本平台账号。若经过本平台判断，认为您使用的账号是恶意注册、获取的号码，本平台有权对相应账号采取限制、中止或终止使用等措施。</p>
        <p>5.2 您在使用本服务时，须通过本平台提供的技术接口、登录页面及其他合法通道等进行平台或第三方账号和个人信息授权等操作;否则，可能会造成的账号泄漏或其他损失等由您自行承担。</p>
        <p>5.3 第三方负责其提供的具体网站、应用或其他服务的合法性、安全性以及真实性，若因任何第三方原因造成您损失的，由第三方承担全部责任。</p>
        <p>5.4您通过本服务使用平台或第三方账号登录、使用的网站、应用或服务等，应遵循您与第三方之间的协议、约定或第三方相关的规则等。对于第三方应用没有约定或约定不明的事项，第三方应用提供者有权援引本协议的条款进行解决，第三方应用提供者相应享有或承担相关条款中本平台所享有或承担的相对于您的权利或义务，但这些条款不适用于解决本平台与第三方应用提供者之间的关系，亦不使本平台因第三方责任而对您产生责任。</p>
        <p>5.5 您理解并同意：本平台在服务中仅提供了登录接口的技术服务，您通过本服务使用平台或第三方账号登录、使用的网站、应用或服务等，均是由第三方提供的。第三方网站、应用或服务等的质量和安全等由第三方独立负责、承担，请您自行进行判断、甄别，并自行承担相关损失、责任。若您因使用第三方网站、应用或服务等产生的任何纠纷，您应与第三方通过协商或其他合法途径解决。</p>
        <p>5.6 您在使用本平台提供的服务过程中，必须遵循法律法规、规章、规范性文件及政策要求的规定，遵守所有与平台、网络服务有关的网络协议、规定和程序，并不得利用本平台从事以下行为：</p>
        <p> &nbsp;&nbsp;&nbsp; (1)发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容;</p>
        <p> &nbsp;&nbsp;&nbsp; (2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容;</p>
        <p> &nbsp;&nbsp;&nbsp; (3)虚构事实、隐瞒真相以误导、欺骗他人;</p>
        <p> &nbsp;&nbsp;&nbsp; (4)发表、传送、传播广告信息及垃圾信息;</p>
        <p> &nbsp;&nbsp;&nbsp; (5)利用平台传输、发布违反国家法律法规政策的任何内容(信息)、涉及国家秘密和/或安全的信息、防碍互联网运行安全的信息;侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容、任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料等，且不得为他人实施上述行为提供任何便利;</p>
        <p> &nbsp;&nbsp;&nbsp; (6)以任何技术手段或其他方式干扰平台及德阳市政府各部门等面向公众的网上服务系统的正常运行或干扰其他用户对平台服务的使用;</p>
        <p> &nbsp;&nbsp;&nbsp; (7)通过任何方式搜集本平台服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户;</p>
        <p> &nbsp;&nbsp;&nbsp; (8)从事其他违反法律法规、政策及公序良俗、社会公德等的行为或对互联网的正常运转造成不利影响的行为。</p>
        <p>5.7 本平台服务仅供您个人使用，除非经本平台书面许可，您不得进行以下行为：</p>
        <p> &nbsp;&nbsp;&nbsp; (1)通过本平台发布包含广告、宣传、促销等内容的信息;</p>
        <p> &nbsp;&nbsp;&nbsp; (2)将本平台提供的服务再次许可他人使用;</p>
        <p> &nbsp;&nbsp;&nbsp; (3)其他超出本平台正常服务功能之外的行为。</p>
        <p>5.8 您充分了解并同意，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容、您进行的各项操作、您进行的授权以及由此产生的任何后果。</p>
        <p>5.9如果本平台发现或收到他人举报您发布的信息违反前述约定或本协议其他约定的，本平台有权进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息链接。同时，本平台有权视您的行为性质，采取包括但不限于暂停或终止本服务、追究法律责任等措施。本平台有权判断用户的行为是否符合平台服务条款规定的权利，如果本平台认为用户违背了本协议、法律法规政策等规定，本平台有权随时变更、中断或终止部分或全部服务，且无需事先告知。</p>
        <p>六、【知识产权声明】</p>
        <p>6.1 本平台在服务中提供的任何软件、网站、商业标识、版面设计、排版方式、文字、图片、图形、音频、视频、有关数据等内容、功能和服务的著作权、商标权、专利权、商业秘密等知识产权均受中华人民共和国法律法规和相应的国际条约保护，本平台所有者或技术支持方享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</p>
        <p>6.2 您在使用本平台的过程中，可能会使用到由第三方开发的在本平台运行的功能、软件或服务，用户除遵守本协议相关规定以外，还应遵守第三方相关规定，并尊重第三方权利人对其功能、软件、服务及其所包含内容的相关权利。</p>
        <p>6.3 未经本平台或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</p>
        <p>七、【第三方提供的服务】</p>
        <p>7.1 本服务可能会使用第三方软件或技术，这种使用已经获得合法授权，您对本协议的签署及继续使用行为视为对接受第三方软件或技术服务的认可。</p>
        <p>7.2如因本平台使用的第三方软件或技术引发的任何纠纷，本平台将协助您与第三方协调解决，您使用本平台上提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的服务或用户协议。本平台和第三方对出现的纠纷在法律规定和约定的范围内各自承担责任。</p>
        <p>八、【风险及免责】</p>
        <p>8.1 您理解并同意：为了向您提供有效的服务，本服务会利用您终端设备的处理器和带宽等资源。在使用本平台过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。</p>
        <p>8.2 由于互联网本身所具有的不稳定性，本平台无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用服务或使用服务受到影响时，本平台对用户或第三方不负任何责任，前述状况包括但不限于：</p>
        <p> &nbsp;&nbsp;&nbsp; (1)本平台系统停机维护、升级期间;</p>
        <p> &nbsp;&nbsp;&nbsp; (2)电信设备出现故障不能进行数据传输时;</p>
        <p> &nbsp;&nbsp;&nbsp; (3)由于黑客攻击、计算机病毒感染、木马或其他恶意程序破坏、网络供应商技术调整或故障、银行方面的问题等本平台不可抗拒因素造成的服务中断或延迟、信息丢失或泄漏等情形;</p>
        <p> &nbsp;&nbsp;&nbsp; (4)用户的电脑软件、系统、硬件和通信线路出现故障;</p>
        <p> &nbsp;&nbsp;&nbsp; (5)用户操作不当或通过非本平台授权的方式使用本服务;</p>
        <p> &nbsp;&nbsp;&nbsp; (6)用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任;</p>
        <p> &nbsp;&nbsp;&nbsp; (7)由于网络信号不稳定、突发、高并发等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等情形;</p>
        <p> &nbsp;&nbsp;&nbsp; (8)因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的;</p>
        <p> &nbsp;&nbsp;&nbsp; (9) 其他本平台无法控制或合理预见的情形。</p>
        <p>8.3 因您的过错导致的任何损失由您自行承担，该过错包括但不限于：不按照提示操作，未及时进行操作，遗忘或泄漏校验码等，您使用的计算机或其他硬件、终端等被他人侵入或丢失，或您使用的软件被他人侵入，或者您的个人生物识别信息被他人利用。</p>
        <p>8.4 您理解并同意，因业务发展需要，本平台有权在提前告知的前提下，对本平台的全部或部分服务内容进行变更、暂停、限制、终止或撤销，用户需承担此风险。</p>
        <p>8.5 本平台会根据您选择的服务类型向您提供相应的服务。您理解并同意，本平台有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况中止或终止向存有包括但不限于以下情形的用户提供本服务：</p>
        <p> &nbsp;&nbsp;&nbsp; (1)违反法律法规或本协议规定的;</p>
        <p> &nbsp;&nbsp;&nbsp; (2)影响使用者体验的;</p>
        <p> &nbsp;&nbsp;&nbsp; (3)存在安全隐患的;</p>
        <p> &nbsp;&nbsp;&nbsp; (4)违背本服务运营原则，或不符合本平台其他管理要求的。</p>
        <p>九、【法律责任】</p>
        <p>9.1 您理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。</p>
        <p>9.2 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任;本平台因此遭受损失的，您也应当一并赔偿。</p>
        <p>9.3 您理解并同意，您可向本平台进行的任何索赔均限于已经发生的直接损失。</p>
        <p>十、【协议的更新、变更】</p>
        <p>10.1 协议的更新</p>
        <p>本协议内容同时包括本平台可能不定期发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，请同样遵守。否则，请立即停止使用本服务。</p>
        <p>10.2 协议的变更</p>
        <p>您使用本服务即视为您已阅读并同意受本协议的约束。本平台有权在必要时修改本协议条款。您在享受各项服务时，可以在相关页面中查阅最新的协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。</p>
        <p>10.3 协议的签署形式</p>
        <p> 如您对本协议条款内容有任何疑问，可向平台客服咨询。一旦点击“同意”或“接受”或“注册”按钮并成功注册，则与本平台之间形成协议关系，应当受本协议条款的约束。</p>
        <p>十一、【法律适用及管辖】</p>
        <p>11.1 本协议的签订地为四川省德阳市。</p>
        <p>11.2 本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。</p>
        <p>11.3 如因本协议或本服务所引起或与其有关的任何争议，双方应尽量友好协商解决，协商不成的，任何一方均可向德阳市自然资源和规划局所在地人民法院提起诉讼解决。</p>
        <p>十二、【通知与送达】</p>
        <p>12.1 所有发给您的通知都可通过您在平台预留的通讯方式，以常规的信件或在网站显著位置公告等方式进行传送。平台将通过上述方法之一将消息传递给您，告知您服务条款的修改、服务变更或其它重要事情。</p>
        <p>12.2 您应当保证和维持资料的有效性，您的资料若存在虚假、无效等任何可能导致您无法及时获取业务通知、客户服务、投诉处理、纠纷协调、技术支持等情况的，由您自行承担相应责任。</p>
        <p>12.3 本平台可以不定期以网页公告、手机短信、站内信、即时通讯工具等方式中的一种或多种向您发送与本服务有关的业务通知、服务提示、验证消息等各种业务通知。</p>
        <p>12.4 各方均应保证其联系方式真实、有效，通知自发送之日视为已送达收件人。</p>
        <p>十三、【其他】</p>
        <p>13.1 本协议自您同意勾选并成功注册为本平台用户之日起生效，除非本平台终止本协议或者您丧失本平台用户资格，否则本协议始终有效。本协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。</p>
        <p>13.2 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
      </div>

    </div>
    <template>
			<span class="dialog-footer">
				<el-button  type="primary"  @click="visible = false">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="needAdd">
import { useDict } from '@/hooks/dict';
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'
import {Message} from 'element-ui'
import {getObj, addObj, putObj,setidCardInfo,getidCardInfo} from '@/service/CommonService';
import { rule } from '@/utils/validator';
import { preventReClick } from '@/utils/preventReClick'

const emit = defineEmits(['refresh']);
const mapDialogRef = ref();

const openMapDialog = () => {
  mapDialogRef.value.openDialog((data) => {
    console.log(data);
  });
};

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);


const onClose= () =>{

}

// 打开弹窗
const openDialog = (id) => {
  visible.value = true;

  nextTick(() => {
    dataFormRef.value?.resetFields();
  });
  // 获取landInfo信息
};





// 暴露变量
defineExpose({
  openDialog,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}
</style>
